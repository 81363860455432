import React from 'react';

import becomeup from "../assets/images/becomeup.png";


const Experience = () => {
  return (
     <div className=" h-96 bg-white">
       <div className="container mx-auto h-full flex">
          <div className="container mx-auto h-full flex hero-content-right bg-white max-w-10xl w-full mx-auto p-">
           <img src={becomeup} alt="hero" class="h-90 object-cover" />
         </div>
         <div className="hero-content-left bg-white max-w-4xl w-full mx-auto p-">
           <h1 className="text-5xl mb-5 fw-4 ">
           Kurzyo vám pomôže získať <br /> informacie kde sa vzdelavať
           </h1>
          <p className="text-5xl fs-39 ">Web je vaším sprievodcom online kurzov a vzdelávacích programov.<br /> Nájdete tu zdroje kde si môžete rozšíriť svoje znalosti a zručnosti. Prechádzajte si ponuku kurzov a začnite investovať do svojho osobného a profesijného rastu.</p>
         </div>
        
       </div>
     </div>
  );
 };


 
 
 export default Experience;
import React from 'react';
import styled from "styled-components";
import { Link } from "react-router-dom";

const Navbar = () => {
 return (
    <NavbarWrapper className="bg-white flex">
      <div className='container w-100'>
        <div className='brand-and-toggler flex flex-between w-100'>
          <Link to="/" className='navbar-brand text-uppercase ls-1 fw-8'>
            <span></span>Kurzyo
          </Link>
          <div className='navbar-btns flex'>
            <Link to="/oprogram" className="nav-link">O programe</Link>
            <a href="https://europa.eu/europass/sk/nastroje-europass/test-your-digital-skills" className="nav-link" target="_blank" rel="noopener noreferrer">Test zručností</a>
            <Link to="/ziaci" className="nav-link">Mladí lídri</Link>
          </div>
        </div>
      </div>
    </NavbarWrapper>
 )
}

const NavbarWrapper = styled.nav`
 height: 80px;
 box-shadow: rgba(50, 50, 93, 0.15) 0px 16px 12px -2px, rgba(0, 0, 0, 0.2) 0px 3px 7px -3px;

 .navbar-brand {
    font-size: 23px;
    span {
      color: var(--clr-orange);
    }
 }
 
 .nav-link {
    color: black;
    font-size: 18px;
    margin-right: 18px;
    transition: all 300ms ease-in-out;
    &:hover {
      color: var(--clr-orange);
    }
 }
`;

export default Navbar;

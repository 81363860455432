import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import '../assets/ziaciPage.css';

const StudentDetails = ({ student }) => (
  <div>
    <h2>{student.name}</h2>
    <p>Age: {student.age}</p>
    <p>Course: {student.course}</p>
  </div>
);

const ZiaciPage = () => {
  const [selectedStudent, setSelectedStudent] = useState(null);
  const students = [
    { id: 1, name: 'Matej Vereš', image: require('../assets/Image/1.JPG')},
    { id: 2, name: 'Ema Nízka', image: require('../assets/Image/2.JPG')},
    { id: 3, name: 'Michal Weber', image: require('../assets/Image/3.JPG')},
    { id: 4, name: 'Vasilina Kidiaieva', image: require('../assets/Image/4.JPG')},
    { id: 5, name: 'Natalia Glembusová', image: require('../assets/Image/5.JPG')},
    { id: 6, name: 'Nina Kubicová', image: require('../assets/Image/6.JPG')},
    { id: 7, name: 'Ivica Viskupičová', image: require('../assets/Image/7.JPG')},
    { id: 8, name: 'Matias Vandák', image: require('../assets/Image/8.JPG')},
    { id: 9, name: 'Matej Slovák', image: require('../assets/Image/9.JPG')},
    { id: 10, name: 'Romana Švecová', image: require('../assets/Image/10.JPG')},
  ];
  const { id } = useParams();
  const student = students.find(s => s.id === parseInt(id));

  const handleStudentClick = (student) => {
    setSelectedStudent(student);
  };

  return (
    <div className="ziakoviaPage">
      <header className="ziaci-page-header">
        <h1>Žiaci, ktorí vám môžu pomôcť</h1>
      </header>
      <div className="separator">
        <hr className="line" />
        <p>Žiaci ZŠ Záhoracka 95</p>
      </div>
      <div className="student-list">
        {students.map((student) => (
          <Link key={student.id} to={`/students/${student.id}`}>
            <div
              className={`student-item ${selectedStudent && selectedStudent.id === student.id ? 'selected' : ''}`}
              onClick={() => handleStudentClick(student)}
            >
              <img src={student.image} alt={student.name} />
              <h2>{student.name}</h2>
            </div>
          </Link>
        ))}
      </div>
      {selectedStudent && <StudentDetails student={selectedStudent} />}
      {student && (
        <div>
          <img src={student.image} alt={student.name} />
          <h2>{student.name}</h2>
        </div>
      )}
    </div>
  );
};

export default ZiaciPage;




/*
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import '../assets/ziaciPage.css';

const StudentDetails = ({ student }) => (
  <div>
    <h2>{student.name}</h2>
    <p>Age: {student.age}</p>
    <p>Course: {student.course}</p>
  </div>
);

const ZiaciPage = () => {
  const [selectedStudent, setSelectedStudent] = useState(null);
  const students = [
    { id: 1, name: 'John Doe', age: 22, Trieda: 'Computer Science' },
    { id: 2, name: 'Jane Smith', age: 24, Trieda: 'Mathematics' },
    { id: 3, name: 'Alice Johnson', age: 21, Trieda: 'Physics' },
  ];
  const { id } = useParams();
  const student = students.find(s => s.id === parseInt(id));

  const handleStudentClick = (student) => {
    setSelectedStudent(student);
  };

  return (
    <div className="ziakoviaPage">
      <header className="ziaci-page-header">
        <h1>Žiaci, ktorí vám môžu pomôcť</h1>
      </header>
      <div className="student-list">
        {students.map((student) => (
          <Link key={student.id} to={`/students/${student.id}`}>
            <div
              className={`student-item ${selectedStudent && selectedStudent.id === student.id ? 'selected' : ''}`}
              onClick={() => handleStudentClick(student)}
            >
              <h2>{student.name}</h2>
              <p>Age: {student.age}</p>
              <p>Trieda: {student.Trieda}</p>
            </div>
          </Link>
        ))}
      </div>
      {selectedStudent && <StudentDetails student={selectedStudent} />}
      {student && (
        <div>
          <h2>{student.name}</h2>
          <p>Age: {student.age}</p>
          <p>Trieda: {student.Trieda}</p>
        </div>
      )}
    </div>
  );
};

export default ZiaciPage;
*/
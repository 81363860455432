import React, { useEffect } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css';
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers';

import '../components/mapStyle.css';

const MapComponent = () => {
  useEffect(() => {
    const map = L.map('map', {
      center: [48.6737532, 19.696058],
      zoom: 8.4,
      zoomControl: false,
      scrollWheelZoom: false,
    });
    
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© OpenStreetMap contributors'
    }).addTo(map);

    const towns = [
      { name: 'ZŠ Záhorácka Malacky', coords: [48.43604, 17.02188], icon: 'home', info: 'Tieto produkty používame', badges: [{ text: 'Canva', color: 'lightblue' },{ text: 'Microsoft 365', color: 'green' },{ text: 'Actionbound.', color: 'orange' },{ text: 'Kahoot', color: 'purple' },{ text: 'Mentimeter', color: 'blue' },{ text: 'Plantnet', color: 'lightgreen' },{ text: 'Freelo', color: 'pink' },{ text: 'Alfbook', color: 'yellow' }] },
      { name: 'Bratislava', coords: [48.148598, 17.107748], icon: 'building', info: 'Tieto produkty používame', badges: [{ text: 'Canva', color: 'lightblue' },{ text: 'Microsoft 365', color: 'green' },{ text: 'Actionbound.', color: 'orange' },{ text: 'Kahoot', color: 'purple' },{ text: 'Mentimeter', color: 'blue' }] },
      { name: 'ZŠ Záhorská Ves', coords: [48.375498498, 16.833996664], icon: 'tree', info: 'Tieto produkty používame', badges: [{ text: 'Canva', color: 'lightblue' },{ text: 'Microsoft 365', color: 'green' },{ text: 'Actionbound.', color: 'orange' },{ text: 'Kahoot', color: 'purple' },{ text: 'Mentimeter', color: 'blue' },{ text: 'Plantnet', color: 'lightgreen' },{ text: 'Freelo', color: 'pink' },{ text: 'Alfbook', color: 'yellow' }] },
      { name: 'ZŠ/ CZA / Reedukačne centrum Veľké Leváre', coords: [48.50318100, 17.00169770], icon: 'church', info: 'This is Velke Levare', badges: [{ text: 'Canva', color: 'lightblue' },{ text: 'Microsoft 365', color: 'green' },{ text: 'Actionbound.', color: 'orange' },{ text: 'Kahoot', color: 'purple' },{ text: 'Mentimeter', color: 'blue' },{ text: 'Plantnet', color: 'lightgreen' },{ text: 'Freelo', color: 'pink' },{ text: 'Alfbook', color: 'yellow' }] },
      { name: 'ZŠ Jakubov', coords: [48.4700, 17.0019], icon: 'hospital', info: 'Tieto produkty používame', badges: [{ text: 'Canva', color: 'lightblue' },{ text: 'Microsoft 365', color: 'green' },{ text: 'Actionbound.', color: 'orange' },{ text: 'Kahoot', color: 'purple' },{ text: 'Mentimeter', color: 'blue' }] },
      { name: 'CVČ Košice', coords: [48.716385, 21.261074], icon: 'hospital', info: 'Tieto produkty používame', badges: [{ text: 'Canva', color: 'lightblue' },{ text: 'Microsoft 365', color: 'green' },{ text: 'Actionbound.', color: 'orange' },{ text: 'Kahoot', color: 'purple' },{ text: 'Mentimeter', color: 'blue' }] },
      { name: 'Prešov', coords: [49.00000000, 21.25000000], icon: 'school', info: 'Tieto produkty používame', badges: [{ text: 'Canva', color: 'lightblue' },{ text: 'Microsoft 365', color: 'green' },{ text: 'Actionbound.', color: 'orange' },{ text: 'Kahoot', color: 'purple' },{ text: 'Mentimeter', color: 'blue' }] },
      { name: 'CVČ Púchov', coords: [49.12494, 18.32597], icon: 'star', info: 'Tieto produkty používame', badges: [{ text: 'Canva', color: 'lightblue' },{ text: 'Microsoft 365', color: 'green' },{ text: 'Actionbound.', color: 'orange' },{ text: 'Kahoot', color: 'purple' },{ text: 'Mentimeter', color: 'blue' }] },
    ];

    towns.forEach(town => {
      const marker = L.marker(town.coords, {
        icon: L.AwesomeMarkers.icon({
          icon: town.icon,
          prefix: 'fa',
          markerColor: 'blue'
        })
      }).addTo(map);

      let badgesHtml = '';
      town.badges.forEach(badge => {
        badgesHtml += `<div class="badge" style="background-color: ${badge.color};">${badge.text}</div>`;
      });

      const popupContent = `
        <div>
          <h4>${town.name}</h4>
          <p>${town.info}</p>
          ${badgesHtml}
        </div>
      `;

      marker.bindPopup(popupContent);
    });

    return () => {
      map.remove();
    };
  }, []);

  return <div id="map" style={{ width: "100%", height: "700px" }}></div>;
};

export default MapComponent;

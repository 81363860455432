import React from 'react';
import Hero from "../components/Hero";
import CoursesList from "../components/CourseList";
import Experience from '../components/experienced';
import MapComponent from '../pages/map';

const HomePage = () => {
  return (
    <div className='holder'>
      <Hero />
      <Experience />
      <CoursesList />
      <MapComponent />
      
     

    </div>
  )
}

export default HomePage